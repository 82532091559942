import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Hahmokuvitus from '../images/4_palapeli.png';
import Heart from '../images/5_rattaat.png';
import Bike from '../images/kuhasalo_kokonainen.jpg';

const Vaaliteemat = () => {
	const styles = {
		redbox: {
			backgroundColor: '#f54b4b',
			minHeight: '300px',
			paddingLeft: '10%',
			paddingRight: '10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#FFF',
		},
		imgBox: {
			backgroundImage: `url(${Hahmokuvitus})`,
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			minHeight: '400px',
		},
		headerImgBox: {
			backgroundImage: `url(${Bike})`,
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			minHeight: '400px',
		},
		pinkbox: {
			backgroundColor: '#f5c8c3',
			paddingLeft: '10%',
			paddingRight: '10%',
			minHeight: '150px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#be0a37',
			paddingTop: '20px',
			paddingBottom: '20px',
		},
		heart: {
			backgroundImage: `url(${Heart})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			minHeight: '200px',
		},
		textbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		redTextbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			backgroundColor: '#be0a37',
			color: '#fff',
		},
	};
	return (
		<>
			<Grid container style={{ width: 'calc(100% + 16px)' }}>
				<Grid item xs={12} md={6} style={styles.redbox}>
					<Typography variant='h2'>Vaaliteemat</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.headerImgBox}></Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Hyvinvointi arjessa kuuluu kaikille</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography variant='body1'>
						Ihmisen hyvinvointi koostuu monista pienistä arjen murusista. Terveyden ja hyvinvoinnin edistäminen ja ongelmien ennaltaehkäisy ovat kaiken perusta. Tätä työtä tekevät yhteistyössä monet toimijat. Hyvän ja monipuolisen palveluketjun turvaamiseksi tarvitaan yhdessä tekemistä
						ja sopimista mm. kuntien ja hyvinvointialueen, järjestöjen, kuin muidenkin toimijoiden kesken. Tärkeää on, että asukkaiden hyvinvointia edistetään laajasti ja huomioidaan ennaltaehkäisevien ja korjaavien palveluiden tuottamisessa myös monipuolisen yhteistyön merkitys.
						<br></br>
						<br></br>
						Hyvinvointia tukee myös tieto ja kokemus siitä, että tarvittaessa on yhdenvertaisesti saatavilla tarpeenmukaiset ja saavutettavat sosiaali- ja terveyspalvelut eri elämäntilanteen mukaisesti. Palveluiden tulee löytyä lähellä ihmistä ja helposti.
						<br></br>
						<br></br>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Kulttuuri sekä liikunta</span> ovat tärkeitä mielihyvän ja hyvinvoinnin lähteitä. Ne ovat keinoja saada positiivisia elämyksiä, työllistyä, viettää mielekästä vapaa-aikaa ja kehittyä yksilönä sekä yhteisön jäsenenä. Kaikkien
						ikäryhmien ja erityisesti lasten ja nuorten matalan kynnyksen toimimis- ja osallistumismahdollisuuksiin tulee kiinnittää huomio.
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography variant='body1'>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Varhaiskasvatus ja koulutus </span>ovat keskeisiä yhteiskunnan tukipilareita, ja niillä on vahva rooli lasten ja nuorten kehityksessä sekä yhteiskunnan hyvinvoinnissa. Laadukas varhaiskasvatus tukee oppimisvaikeuksissa sekä
						sosiaalisia taitoja. Se antaa myös tasapuoliset lähtökohdat koulupolulle.
						<br></br>
						<br></br>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Korkeakoulutus ja elinikäinen oppiminen: </span> Perusopetuksen jälkeen toisen asteen vaihtoehdot antavat nuorille valinnanmahdollisuuksia. Joensuussa tulee jatkossakin olla monipuolinen ammatillinen ja lukiokoulutus.
						Opiskelijakaupunki Joensuussa kouluttautumismahdollisuuksia tulee vahvistaa sekä monipuolistaa yhteistyössä huomioiden työelämän tarpeet. Kansalaisopiston kaikenikäisille tarkoitetut monipuoliset oppimismahdollisuudet ovat monelle tärkeä, hyvinvointia laajasti edistävä
						mahdollisuus. Eri kouluttautumismahdollisuuksien tulee olla saavutettavia sekä taloudellisesti jokaiselle mahdollisia.
						<br></br>
						<br></br>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Meillä kaikilla,</span> myös vammaisilla, on iästä riippumatta paljon erilaisia voimavaroja, jotka voimme antaa yhteiseen käyttöön. Siksi kaikissa hyvinvointialueen sekä kuntien toiminnoissa, suunnittelusta päätöksentekoon
						ja toteutukseen, tuleekin turvata asukkaille esteetön elin- ja toimintaympäristö, joka mahdollistaa osallisuuden, omien voimavarojen hyödyntämisen sekä itsenäisen toimimisen. Toteutuessaan oikea-aikaiset ja monipuoliset kuntoutuspalvelut takaavat yhdenvertaisuuden
						toteutumisen.
					</Typography>
				</Grid>

				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Yhteistyöllä ja osallisuutta vahvistaen tulevaan</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.heart}></Grid>
				<Grid item xs={12} md={6} style={styles.redTextbox}>
					<Typography variant='body1'>
						Järjestöillä sekä kansalais- ja vapaaehtoistoiminnalla on merkittävä hyvinvointia lisäävä rooli yhteiskunnassa monella eri tasolla, niin Joensuussa kuin hyvinvointialueella. Vapaaehtoistoiminta lisää merkityksellisyyden tunnetta, ehkäisee yksinäisyyttä, vahvistaa osallisuutta
						ja parantaa mielenterveyttä. Lisäksi monipuolinen järjestö- ja vapaaehtoistoiminta lisää fyysistä ja liikunnallista aktiivisuutta. Yhdessä toimiminen tarjoaa uusien asioiden oppimista ja uusia taitoja sekä vahvistaa yhteistyökykyä. Tänä päivänä järjestö- ja
						vapaaehtoistoiminnalla on merkittävä osa eri palveluketjujen kokonaisuuksissa, jotka vahvistavat osaltaan myös yksilön osallisuutta ja yhteisöllisyyttä.
						<br></br>
						<br></br>
						Osallisuutta edistävien toimien ja tehtävien yksi perusedellytys on kaupungin, hyvinvointialueen, eri järjestöjen, kansalais- ja vapaaehtoistoimijoiden kanssa ja kesken tehtävä yhteistyö. Näin saadaan myös eri palvelujen kehitystehtäviin laajaa arjen kokemustietoa yhteiseen
						käyttöön.
						<br></br>
						<br></br>
						On tärkeää tunnistaa järjestötoiminnan kokonaismerkitys, myös sen työllisyys- ja talousvaikutukset: ilman järjestöjen panosta moni tärkeä palvelu tai osallistumismahdollisuus jäisi toteutumatta. Joensuun ja hyvinvointialueen tulee osaltaan olla edesauttamassa toimintaa ja
						mahdollistamassa riittävät toimimismahdolliuset, taloudelliset resurssit ja mm. järjestötoimintaa tukevat esteettömät ja ääniystävälliset tilat.
					</Typography>
					
				</Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h2'>Elinvoimaa, hyviä palveluja ja hyvinvointia</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.textbox}>
					<Typography>
						<span style={{ color: '#be0a37', fontWeight: 800 }}>Joensuun kaupungin ja Pohjois-Karjalan maakunnan veto- ja elinvoimaisuuden vahvistuminen on meidän kaikkien yhteinen etu.</span> Elinvoimaisuus muodostuu monista asioista: Vahvaa elinvoimaisuutta tukevat hyvin toimivat
						terveyttä ja hyvinvointia edistävät palvelut, sosiaali- ja terveyspalvelut, monipuolinen järjestö- ja yritystoiminta sekä asukkaiden osallisuuden mahdollisuudet — Toimiva ihmisen arki.
						<br></br>
						<br></br>
						Elinvoimaisuus edellyttää myös työtä yritysten ja toimijoiden sijoittumiseksi tänne sekä hyviä koulutus- ja opiskelumahdollisuuksia. Nämä toimet osaltaan tukevat myös rekrytoitaessa työntekijöitä Joensuuhun ja hyvinvointialueelle. Työntekijöiden työhyvinvointia ja
						työolosuhteita ei myöskään saa unohtaa vaan näitä tekijöitä tulee yhteisvoimin kehittää.
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.imgBox}></Grid>
			</Grid>
		</>
	);
};

export default Vaaliteemat
