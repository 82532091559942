import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Linna from '../game-images/pielisjoenlinna.jpg'
import OpenInNew from '@material-ui/icons/OpenInNew';

const Kirjoituksia = () => {
	const styles = {
		redbox: {
			backgroundColor: '#f54b4b',
			minHeight: '200px',
			paddingLeft: '10%',
			paddingRight: '10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#fff',
		},
		pinkbox: {
			backgroundColor: '#f5c8c3',
			paddingLeft: '20%',
			paddingRight: '20%',
			minHeight: '150px',
			padding: '60px 0',
			textAlign: 'center',
		},
		textbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		imgBox: {
			backgroundImage: `url(${Linna})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			minHeight: '500px',
			//backgroundAttachment: 'fixed',
		},
	};
	return (
		<>
			<Grid container style={{ width: 'calc(100% + 16px)' }} justify='center'>
				<Grid item xs={12} md={6} style={styles.redbox}>
					<Typography variant='h2'>Kirjoituksia</Typography>
				</Grid>
				<Grid item xs={12} md={6} style={styles.imgBox}></Grid>

				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h3'>Kirjoituksia Itäsuomalaisessa</Typography>
				</Grid>

				<Grid item xs={12} className='border-bottom'>
					<Typography variant='body1' style={styles.textbox}>
						<a href='https://www.itasuomalainen.fi/uutiset--politiikka/mielipide-kuulokojejonoja-voidaan-purkaa-6.93.7925.f7d2f54323' target='_blank' className='article-link'>
							<h3>Mielipide: Kuulokojejonoja voidaan purkaa</h3>
							<OpenInNew />
							<i>Julkaistu Itäsuomalaisessa 3.2.2025</i>
						</a>
					</Typography>
				</Grid>
				<Grid item xs={12} className='border-bottom'>
					<Typography variant='body1' style={styles.textbox}>
						<a href='https://www.itasuomalainen.fi/uutiset--politiikka/sdp-joensuu-muisti-toimijoitaan--eila-heinoselle-rafael-paasio-mitali-6.93.7275.2fc1bbd04c' target='_blank' className='article-link'>
							<h3>SDP Joensuu muisti toimijoitaan – Eila Heinoselle Rafael Paasio -mitali</h3>
							<OpenInNew />
							<i>Julkaistu Itäsuomalaisessa 5.11.2024</i>
						</a>
					</Typography>
				</Grid>
				<Grid item xs={12} className=''>
					<Typography variant='body1' style={styles.textbox}>
						<a href='https://www.itasuomalainen.fi/uutiset--politiikka/mielipide-kuulon-merkitysta-vahatellaan-6.93.5591.92a1444e2e' target='_blank' className='article-link'>
							<h3>Mielipide: Kuulon merkitystä vähätellään</h3>
							<OpenInNew />
							<i>Julkaistu Itäsuomalaisessa 3.3.2024</i>
						</a>
					</Typography>
				</Grid>

				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h3'>Nyt tarvitaan tulevaan katsovaa laaja-alaista näkemystä</Typography>
				</Grid>

				<Grid item xs={12} md={6}>
					<Typography variant='body1' style={styles.textbox}>
						<i>Julkaistu Karjalaisessa 16.1.2022</i>
						<br></br>
						<br></br>
						Hyvinvointialuevaalien keskusteluissa näyttää korostuvan -te- toki meille kaikille tärkeät ja laaja-alaiset terveyspalvelut. Mutta miten on -So- , sosiaalipalvelut, -pela- pelastustoimi, tai meillä myös ympäristöterveydenhuolto?
						<br></br>
						<br></br>
						Kaikki ovat meille tärkeitä toiminta-alueita, etenkin avun hetkellä. Ne ovat tärkeitä asioita puhuttaessa ennakoivista toimista ja palveluista. Palveluissa, joissa korostuvat myös eri järjestöjen tekemä vapaaehtoistyön panos sekä yhteistyö julkisten toimijoiden kanssa.
						<br></br>
						<br></br>
						Siun soten organisaatiossa on yhdistetty perhe- ja sosiaalipalvelut yhdeksi toimialueeksi. Sieltä löytyy kotihoito, asumispalvelua, tukipalveluja, omais- ja perhehoidon palvelua, palveluohjausta, gerontologinen sosiaalityö jne.  Paljon palveluja, arjen tukitoimia vauvasta
						ikäihmiseen, yksinasuvista perheisiin, eri elämäntilanteessa olevien asukkaiden tueksi asuinpaikasta riippumatta.
						<br></br>
						<br></br>
						Perjantaina luimme Karjalaisesta pela-ammattilaisten pulasta. Olemme isojen asioiden äärellä. Hyvinvointialueen erityispiirteet ja erilaiset toimialueet edellyttävät myös päätöksenteossa ja kehittämistyössä tulevaan katsovaa kokonaisvaltaista ajattelua, monialaista, laajaa
						kokemusta ja yhteistyötä työntekijöiden, asiakkaiden, asukkaiden ja päättäjien kesken.
						<br></br>
						<br></br>
						Hyvä on myös muistaa käytössä jo olevat monet hyvät toimintamallit ja -tavat. Niistä on hyvä edetä yhdessä toimien asukkaiden ja palvelujen tarvitsijoiden parhaaksi ja inhimillisen arjen turvaamiseksi. Vaikutetaan ja äänestetään.
						<br></br>
						<br></br>
						<b>Eila Heinonen</b>
						<br></br>
						<i>aluevaaliehdokas (sdp) </i>
						<br></br>
						<i>kaupunginvaltuutettu</i>
						<br></br>
						<i> mielenterveyshoitaja, eläkkeellä</i>
						<br></br>
						<i>vapaaehtois- ja järjestötoimija</i>
					</Typography>
				</Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h3'>Yhteistyössä työhyvinvointia - Siun soten toiminnan perusta on hyvinvoiva henkilöstö</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant='body1' style={styles.textbox}>
						<i>Julkaistu Karjalaisessa 3.5.2021</i>
						<br></br>
						<br></br>
						Siun soten toiminnan perustana on osaava, innostunut ja hyvinvoiva henkilöstö. Henkilöstölle järjestetty neljäs työhyvinvointikysely "Mitä Siulle kuuluu?" on valmistunut. Kyselyyn osallistui 72 prosenttia kuntayhtymän noin 7300 työntekijästä.
						<br></br>
						<br></br>
						Kyselyllä on saatu ajankohtaista, tärkeää sekä vertailevaa tietoa henkilöstön työhyvinvoinnin tilasta. Kyselyn tuloksissa näkyy hyvin missä työhyvinvointia edistävissä asioissa on edetty, sekä myös se mihin asioihin olisi syytä työhyvinvoinnin edistämiseksi edelleen
						keskittyä.
						<br></br>
						<br></br>Kysely antaakin paljon eväitä jatkotyöskentelyyn niin onnistumiskokemusten kuin kehittämiskohteiden osalta. Kyselyn tuloksista ilmenee muun muassa, että aiempaa useampi kokee muutokset työssä myönteisiksi, mutta toisaalta liian moni kokee, että ei voi vaikuttaa
						muutoksiin ja olla mukana suunnittelussa.
						<br></br>
						<br></br>
						Siksi on tärkeää, että henkilöstön kanssa pohditaan yhteisistä voimavaratekijöistä sekä kehittämisen keinoista, havaittujen tarpeiden pohjalta. Yhteistyössä valmistellut toimenpiteet edistävät parhaiten myös henkilöstön työhyvinvointia ja jaksamista.
						<br></br>
						<br></br>
						Siun soten toiminnan ja toimintojen uudistamisen sekä uudelleen organisointien myötä on toteutunut monia muutoksia. Henkilökunta on ollut usein ja joustavasti uuden edessä kehittämässä ja toteuttamassa varsinaista kuntayhtymän tehtävää.
						<br></br>
						<br></br>
						Myös ajankohtainen koronatilanne on myös vaikuttanut vahvasti ja eri tavoin henkilöstön työhön eri yksiköissä Siun soten toimialueella. Koronan aiheuttamat muutokset, sopeutumiset ovat toteutuneet myös hyvin nopealla aikataululla. Tämän pitkittyneenkin poikkeavan ajan, monien
						erityisjärjestelyjen ohella, on pystytty varmistamaan Siun soten laajaa toimintaa koko henkilökunnan voimin ja näin turvaamaan asukkaiden arkea.
						<br></br>
						<br></br>
						Siun soten valtuuston kokouksessa 17.3.21 SDP:n ja Vasemmistoliiton valtuustoryhmä on jättänyt valtuustoaloitteen esittäen, että henkilöstön kanssa yhteistyössä valmistellaan malli, miten ja millä keinoin työhyvinvointia tukien tulisi henkilökuntaa kiitoksena huomioida kun,
						koronatilanne sen mahdollistaa.
						<br></br>
						<br></br>
						<b>Eila Heinonen</b>
						<br></br>
						<br></br>
						<i>Siun soten SDP:n ja Vasemmistoliiton valtuustoryhmän varapuheenjohtaja (sdp)</i>
					</Typography>
				</Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h3'>Päätösten vammaisvaikutusten arviointi huomioon Joensuussa</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant='body1' style={styles.textbox}>
						<i>Julkaistu lyhennettynä Karjalaisessa 1.6.2021</i>
						<br></br>
						<br></br>
						Suomi on ratifioinut jo vuonna 2016 YK:n vammaisten henkilöiden oikeuksia koskevan yleissopimuksen, jonka tarkoituksena on taata vammaisille henkilöille täysimääräisesti ja yhdenvertaisesti kaikki ihmisoikeudet ja perusvapaudet sekä edistää vammaisten henkilöiden ihmisarvon
						kunnioittamista. Sopimus velvoittaa myös huomioimaan vammaisten henkilöiden erityistarpeet ja takaamaan vammaisille samat yhteiskunnallisen osallistumisen mahdollisuudet kuin kaikille muillekin. Lisäksi se korostaa yleisen asennemuutoksen tärkeyttä, stereotypioiden ja
						ennakkoluulojen poistamisen merkitystä ja yleistä tietoisuuden lisäämistä vammaisten aseman edistämiseksi ja ihmisoikeuksien toteutumiseksi.
						<br></br>
						<br></br>
						Vammaiset ovat maailman suurin syrjityin ihmisryhmä. Vammaisten ihmisten yhdenvertaiset osallistumismahdollisuudet ja palveluiden saaminen eivät ole vielä täysin toteutuneet Suomessakaan. Hyvin usein vammaisiin kohdistuu ennakkoluuloja, syrjintää, poissulkemista ja jopa
						väkivaltaa. Vammaisilta saatetaan evätä oikeus koulutukseen, terveydenhuoltoon ja yhteiskunnalliseen osallistumiseen ainoastaan heidän vammansa perusteella. Vammaispalveluissa ei ole kyse pelkästään sosiaali- ja terveystoimessa myönnettävistä palveluista tai ylimääräisestä
						lisäpalvelusta, vaan lailla turvatusta oikeudesta yhdenvertaiseen osallistumiseen yhteiskunnassa. Tästä syystä kaikessa päätösten valmistelussa tulee huomioida myös niiden vaikutukset vammaisten ihmisten elämään.
						<br></br>
						<br></br>
						Myös Joensuun kaupungin tulee kantaa oma vastuunsa vammaisten oikeuksien toteutumisesta ja näin ollen päätösten valmistelutyössä tulee ottaa entistä vahvemmin huomioon vaikutukset vammaisiin ja tätä kautta lisätä vammaisten henkilöiden yhdenvertaisuutta ja itsenäisyyttä sekä
						parantaa heidän mahdollisuuksiaan osallistua yhteiskunnan eri toimintoihin täysimääräisesti.
						<br></br>
						<br></br>
						Edellä olevan perusteella Joensuun SDP:n valtuustoryhmä on jättänyt valtuustoaloitteen, että Joensuussa tulee ottaa käyttöön päätösten vammaisvaikutusten arviointi, jotta päätösten vaikutukset vammaisten ihmisten elämään ja oikeuksien toteutumiseen voidaan ottaa
						läpileikkaavasti huomioon kaikilla politiikan osa-alueilla.
						<br></br>
						<br></br>
						<b>Eila Heinonen</b>
						<br></br>
						<br></br>
						<i>SDP:n valtuustoryhmän puheenjohtaja kuntavaaliehdokas (sdp)</i>
					</Typography>
				</Grid>
				<Grid item xs={12} style={styles.pinkbox}>
					<Typography variant='h3'>Keskivaikea kuulovamma oli Eila Heinosella jo syntyessään – Nyt hän auttaa vertaisiaan vapaaehtoisena kuuloaktiivina</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant='body1' style={styles.textbox}>
						<a href='https://seura.fi/terveys/terveystarinat/keskivaikea-kuulovamma-eila-heinonen-auttaa-vertaisiaan-kuuloaktiivina/' target='_blank' className='article-link'>
							Eila Heinosella, 65, on ollut kuulovamma lapsesta saakka. Kuulolaitteen hän sai vasta oltuaan pitkään työelämässä. Nyt hän kertoo, miten jokainen voi toiminnallaan joko helpottaa tai hankaloittaa kuulovammaisen elämää.
							<br></br>
							<br></br>
							<OpenInNew />
							<i>Lue lisää: Linkki Seura-lehden artikkeliin, julkaistu 28.09.2020</i>
						</a>
					</Typography>
				</Grid>
			</Grid>
		</>
	);
};

export default Kirjoituksia
