import React from 'react'
import { Typography, Grid, List, ListItem, ListItemIcon } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import Kuva1 from '../images/eila_heinonen_vaalit_2025.jpg';
import Kampanjatunnus2025 from '../images/SDP-kampanjatunnus-2025-rgb-2r-kesk-fi.png';

const styles = {
	redbox: {
		backgroundColor: '#f54b4b',
		//minHeight: '600px',
		padding: '10% 10% 10% 10%',
		//paddingRight: '10%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		color: '#fff',
	},
	imgBox: {
		backgroundImage: `url(${Kuva1})`,
		backgroundSize: 'cover',
		backgroundPosition: 'top',
		minHeight: '400px',
	},
	pinkbox: {
		backgroundColor: '#f5c8c3',
		/*paddingLeft: '10%',
		paddingRight: '10%',*/
		paddingTop: '20px',
		paddingBottom: '20px',
		minHeight: '150px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	textbox: {
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingTop: '40px',
		paddingBottom: '40px',
		color: '#28282d',
	},
	videobox: {},
	redbg: {
		backgroundColor: '#f54b4b',
		minHeight: '100px',
		color: '#fff',
	},
	circle: {
		height: '100px',
		width: '100px',
		backgroundColor: '#fff',
		color: '#f54b4b',
		borderRadius: '50%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: '10px',
	},
	linkbox: {
		paddingLeft: '10px',
		paddingRight: '10px',
		paddingTop: '10px',
		paddingBottom: '30px',
	},
	reilumminImg: {
		maxHeight: '80px',
		paddingTop: '30px',
	},
};

const Head = () => {
	return (
		<Grid container style={{ width: 'calc(100% + 16px)' }}>
			<Grid item xs={12} sm={9} style={styles.redbox}>
				<Grid item xs={12}>
					<Typography variant='h1'>Eila Heinonen</Typography>
					<Typography variant='h4'>Joensuu</Typography>

					<Typography variant='h4' style={{ marginTop: '20px' }}>
						Ehdolla kunta- ja aluevaaleissa 2025
					</Typography>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={3} style={styles.imgBox}></Grid>
			{/* <Grid item xs={12} style={styles.videobox}>
				<div className='videoframe'>
					<iframe title='videoplayer' src='https://drive.google.com/file/d/1paIm9o9RHZXydYDXqfXiN_G8VL3HLb0R/preview' className='videocontent' allow='autoplay'></iframe>
				</div>
			</Grid> */}
			<Grid item xs={12} md={4} style={styles.pinkbox}>
				<Grid container>
					<Grid item xs={12} className='content-center mb-20'>
						<Typography variant='h3' className=''>
							Vaalit koskettavat juuri Sinua
						</Typography>
					</Grid>
					<Grid item xs={6} className='content-center'>
						<div className='number-title'>Kuntavaalit</div>
						<div className='number-circle bg-white'>
							<p>173</p>
						</div>
					</Grid>
					<Grid item xs={6} className='content-center'>
						<div variant='body1' className='number-title'>
							Aluevaalit
						</div>
						<div className='number-circle bg-lavender'>
							<p>2107</p>
						</div>
					</Grid>
				</Grid>
				{/*<Typography variant='h3'>Hyvinvointialuevaalit koskettavat juuri Sinua</Typography>*/}
			</Grid>
			<Grid item xs={12} md={8} style={styles.textbox}>
				<Typography variant='body1'>
					Jokainen ihminen tarvitsee turvallisuutta, välittämistä ja sujuvaa arkea. Se edellyttää myös luottamusta siihen, että yhteiskunta kannattelee meitä jokaista, että tarvittaessa pääsemme hoitoon, saamme tukea ja turvaa, koulutusta ja työtä. Tiedämme, että hyvinvointivaltio
					muodostaa meidän turvaverkon, joka kantaa niin arjessa kuin kriisien aikana.
					<br></br>
					<br></br>
					Pohjois-Karjalan hyvinvointialueen ja maakunnan keskuskaupunki Joensuun tulee olla inhimillinen, oikeudenmukainen sekä tarpeenmukaisten palvelujen järjestäjä. Vaaleissa valittujen päättäjien arvot ja valinnat ratkaisevat, millaiset hyvinvoinnin ja terveyden edellytykset ovat
					seuraavan vaalikauden aikana. Terveyden ja hyvinvoinnin edistäminen ja ongelmien ehkäisy ovat kaiken perusta. Tässä onnistuminen edellyttää vahvaa yhteistyötä hyvinvointialueen, kuntien, järjestöjen, asukkaiden ja muiden toimijoiden kanssa ja kesken. Tätä yhteistyötä tulee
					edelleen monipuolistaa ja näin vahvistaa osallisuutta ja saada samalla monitahoista osaamista ja tietoa yhteiseen käyttöön.
					<br></br>
					<br></br>
					Alueen ja Joensuun asukkaiden avain hyvinvointiin ovat yhdenvertaiset, saavutettavat ja laadukkaat palvelut. Miten vahvistetaan työllistymistä, elinvoimaisuutta ja yrittäjyyttä. Miten tuemme paikallisuutta ja kaupan palveluja. Miten varhaiskasvatus, koulutus, kulttuuri, liikunta
					ja kaikki vapaa-ajan toiminnot ovat osaltaan hyvinvointia vahvistamassa. Miten kaavoitus tai tilat tukevat elinvoimaa ja yleistä viihtymistä? Miten nämä kaikki yhdessä mahdollistavat arjen turvallisuuden ja elämän sujuvuuden.
					<br></br>
					<br></br> Samantekevää ei ole se, kuka tai ketkä päätöksiä tekevät. Vaaleissa äänestämällä on mahdollisuus vaikuttaa suoraan siihen, kuka ja miten asioihin voi vaikuttaa jatkossakin.
				</Typography>
				<br></br>
				<Typography style={{ color: '#be0a37', fontWeight: '600' }}>Otetaan yhdessä hyvinvoinnin seuraava askel ja pidetään kaikki mukana.</Typography>
				<div className='content-center'>
					<img src={Kampanjatunnus2025} style={styles.reilumminImg} />
				</div>
			</Grid>
			<Grid item xs={12} style={styles.textbox}>
				<List>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Turvataan tarpeenmukaisten, oikea-aikaisten ja saavutettavien palvelujen saatavuus iästä, asuinpaikasta tai elämäntilanteesta riippumatta</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Huomioidaan asukkaat sekä myös vammaiset ja heitä edustavat järjestöt mukaan alueen päätöksentekoon ja sote-palvelujen kehittämiseen</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Tuetaan järjestöjen yhteistyötä ja tiedotetaan järjestöjen tarjoamista vertais- ja vapaaehtoistoiminnan mahdollisuuksista</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Huomioidaan vertaistoiminnan mahdollisuudet palveluketjuissa ja hyvinvointia ja terveyttä edistävässä työssä</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Turvataan oikea-aikaiset, tarpeenmukaiset sosiaali- ja kuntoutuspalvelut, esimerkiksi laadukas ja kynnyksetön kuulon-, sekä mielenterveyskuntoutus ovat tärkeitä panostuksia väestön toimintakykyyn</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Turvataan pelastustoimen sekä sosiaali- ja terveystoimen tiiviin yhteistyön mahdollisuudet palvelujen kehitystyössä</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Turvataan laadukas varhaiskasvatus ja koulutuspolku jokaiselle Joensuun lapselle ja nuorelle</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Mahdollistetaan jokainen lapsi harrastamaan</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Toimitaan aktiivisesti työttömyyttä vastaan</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Tuetaan paikallista yrittäjyyttä</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Kehitetään yhteisvoimin työntekijöiden työhyvinvointia ja työolosuhteita</Typography>
					</ListItem>
					<ListItem>
						<ListItemIcon>
							<FavoriteIcon class='red' />
						</ListItemIcon>
						<Typography>Tavoitteena palvelujen yhdenvertaisuus, saavutettavuus, laadukkuus, oikea-aikaisuus, elämän mielekkyys ja arjen turvallisuus kaikenikäisenä</Typography>
					</ListItem>
				</List>

				<Typography variant='h5' style={styles.textbox}>
					<a class='hoverlink' target='_blank' href='https://joensuu.sdp.fi/etusivu/kuntavaalit/joensuun-tulevaisuusohjelma/'>
						Lue lisää: Vaaliohjelma — Savo-Karjalan Sosialidemokraatit
					</a>
				</Typography>
			</Grid>
			<Grid item xs={4} style={styles.linkbox}>
				<a href='/vaaliteemat'>
					<Typography variant='h5' className='redlink' align='center'>
						Vaaliteemat
					</Typography>
				</a>
			</Grid>
			<Grid item xs={4} style={styles.linkbox}>
				<a href='/kirjoituksia'>
					<Typography variant='h5' className='redlink' align='center'>
						Kirjoituksia
					</Typography>
				</a>
			</Grid>
			<Grid item xs={4} style={styles.linkbox}>
				<a href='/kukaolen'>
					<Typography variant='h5' className='redlink' align='center'>
						Kuka olen?
					</Typography>
				</a>
			</Grid>

			<Grid item xs={12} style={styles.redbg}>
				<Typography variant='h5' style={{ textAlign: 'center', paddingTop: '40px', paddingBottom: '40px' }}>
					Joensuu-aiheinen muistipeli mielen virkistämiseksi!
				</Typography>
			</Grid>
		</Grid>
	);
};
export default Head
