import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Kokokuva from '../images/eila_heinonen_vaalit_2025.jpg';
import Nimmari from '../images/eilaheinonen_nimmari.png';

const KukaOlen = () => {
	const styles = {
		redbox: {
			backgroundColor: '#f54b4b',
			minHeight: '200px',
			paddingLeft: '10%',
			paddingRight: '10%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: '#fff',
		},
		imgBox: {
			backgroundImage: `url(${Kokokuva})`,

			//backgroundAttachment: 'fixed',
		},
		textbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		listbox: {
			fontFamily: 'Barlow',
			fontWeight: '400',
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			color: '#28282d',
		},
		pinkbox: {
			backgroundColor: '#f5c8c3',
			paddingLeft: '10%',
			paddingRight: '10%',
			minHeight: '150px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		redTextbox: {
			paddingLeft: '10%',
			paddingRight: '10%',
			paddingTop: '40px',
			paddingBottom: '40px',
			backgroundColor: '#be0a37',
			color: '#fff',
		},
		signImg: {
			maxHeight: '65px',
			paddingTop: '10px',
		},
	};
	return (
		<Grid container style={{ width: 'calc(100% + 16px)' }}>
			<Grid item xs={12} style={styles.redbox}>
				<Typography variant='h1'>Kuka olen?</Typography>
			</Grid>
			<Grid item xs={12} sm={7}>
				<Typography variant='body1' style={styles.textbox}>
					Olen kotoisin Pukkilasta, Itä-Uudeltamaalta. Molemmat vanhempani olivat mukana kunnallispolitiikassa ja vapaaehtoistoiminnoissa. Kotimme ovet olivat auki alueen asukkaille. Näin ja koin samalla miten suuri vaikutus voi olla sillä, että ottaa asioista selvää, kuuntelee, kannustaa
					ja vie asioita edelleen laajempaan keskusteluun. Itse kuulo-, ja nuoruusvuosiin saakka myös puhevammaisena, sain kokea eteenpäin vievää, vahvaa kannustusta ja luottamusta tekemisiini ja valintoihini. Tämä kokemus ohjaa myös omaa toimintaani.
					<br></br>
					<br></br>
					Olen koulutukseltani mielenterveyshoitaja ja sosiaaliohjaaja. Lisäksi olen suorittanut mm. työelämää tukevia erikoistumisopintoja ja nyt tuoreimpana esimerkkinä luottamustehtäviä tukevan hyvän hallinnon, HHJ-koulutuksen.
					<br></br>
					<br></br>
					Asun Joensuun maalaismaisemissa Multimäen alueella. Elän neljän sukupolven ketjussa ja näen eri ikäkausien muuttuvat, myös eri palveluihin liittyvät tarpeet. Perheeseeni kuuluu aviopuoliso ja hänen isänsä sekä kaksi aikuista lasta puolisoineen ja viisi lastenlasta (11-23 v).
					<br></br>
					<br></br>
					Politiikassa, erilaisissa luottamustehtävissä olen saanut olla mukana koko aikuisiän. Yhteisten asioiden parissa aloitin Ay-toiminnassa, ja pian sen rinnalle tuli kunnallisvaikuttaminen. Olen kuluneella vaalikaudella ollut Joensuun kaupunginvaltuutettu ja kaupunginhallituksen
					jäsen, sekä hyvinvointialueen valtuutettu ja aluehallituksen jäsen, ja aluehallituksen edustajana tulevaisuuslautakunnassa. Siun sotea perustettaessa olin sen ensimmäinen valtuuston puheenjohtaja.
					<br></br>
					<br></br>
					Vuosien saatossa olen vapaaehtois-, yhdistys- ja järjestötyössä toiminut paikallisella, alueellisella sekä valtakunnantasolla. Parhaillaan olen Joensuun Seudun Kuulon ja Itä-Suomen kuulopiirin puheenjohtaja sekä mielenterveysomaisissa, FinFami Pohjois-Karjalan hallituksen jäsen.
					<br></br>
					<br></br>
					Työurallani toimin psykiatrian eri yksiköissä ja avohoidon kotiin vietävissä kuntoutuspalveluissa. Näin minulla oli mahdollisuus toimia mielenterveyskuntoutujien ja myös läheisten kanssa. Työni ja oman kokemukseni kautta olen saanut nähdä läheltä ihmisen arkea ja miten arki voi
					muuttua sairauden, erilaisten elämäntilanteiden myötä.
					<br></br>
					<br></br>
					Koen, että työkokemukseni, koulutukseni ja laaja, monipuolinen luottamustoimien hoito eri julkisissa organisaatioissa, järjestöissä ja vapaaehtoistoiminnassa ovat auttaneet minua näkemään asioita niin yhden ihmisen kuin laajemmin yhteisöjen näkökulmasta.
				</Typography>
			</Grid>
			<Grid item xs={12} sm={5} style={styles.imgBox} className='img-box'></Grid>
			<Grid item xs={12} style={styles.pinkbox}>
				<Typography variant='h3'>Nykyisiä luottamustehtäviä</Typography>
			</Grid>
			<Grid item xs={12} style={styles.listbox}>
				<ul>
					<li>Pohjois-Karjalan hyvinvointialueen valtuutettu, aluehallituksen jäsen, Sdp-valtuustoryhmän varapuheenjohtaja</li>
					<li>Kaupunginvaltuutettu ja kaupunginhallituksen jäsen, Sdp-valtuustoryhmän vetäjä</li>
					<li>Maakuntavaltuutettu sekä sdp-valtuustoryhmän vetäjä</li>
					<li>Joensuun evlut seurakuntayhtymän kirkkovaltuutettu ja Pielisensuun srk:n seurakuntaneuvoston jäsen</li>
					<li>Kuuloliiton Itä-Suomen kuulopiirin puheenjohtaja </li>
					<li>Joensuun Seudun Kuulo ry:n puheenjohtaja </li>
					<li>Pohjois-Karjalan Osuuspankin edustajiston jäsen </li>
				</ul>
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography variant='body1' style={styles.textbox}>
					<span style={{ color: '#be0a37', fontWeight: 800 }}>Näkemystäni laajentavat </span>vuosien varrella olleet mahdollisuudet osallistua ja olla eri kunnallisissa lautakunnissa, käräjäoikeuden lautamiehenä, eri AY-tehtävissä MKL/KTV/JHL, jne. Monipuolista kokemusta olen saanut myös
					työni kautta. Toimiminen lähellä ihmisen arkea monenlaisissa tilanteissa sekä omakohtainen kokemus kuulo- ja puhevammaisuudesta ovat antaneet minulle mahdollisuuden nähdä elämää erilaisista lähtökohdista käsin.
				</Typography>
			</Grid>
			<Grid item xs={12} md={6} style={styles.redTextbox}>
				<Typography variant='body1'>
					Vahvistan yhteistyötä ja osallisuutta päätöksenteossa, jotta jokaisen ääni kuuluu. Hyödynnän kokemustani vastuullisen taloudenpidon varmistamiseksi, jotta voimme rakentaa yhdenvertaista ja kestävää tulevaisuutta kaikille.
					{/*Uskon, että kokemustaustani antaa vahvaa pohjaa hyvinvointialueen eri toimijoiden kanssa tehtävään yhteistyöhön sekä päätöksentekoon. Pidetään huolta tarpeenmukaisten palvelujen saatavuudesta, oikea-aikaisuudesta ja saavutettavuudesta, iästä, asuinpaikasta tai elämäntilanteesta
					riippumatta.*/}
					<br></br>
					<br></br>
				</Typography>
				<Typography variant='h3' style={{ color: '#fff' }}>
					Tavoitteena hyvinvoiva asukas sekä elinvoimainen maakunta ja sen keskus Joensuu.
				</Typography>
				<Typography>
					<br></br>Pidetään yhteyttä!
				</Typography>
				<div>
					<img src={Nimmari} style={styles.signImg} />
				</div>
				<a href='mailto:eila.heinonen@luottamus.joensuu.fi' class='mail-link'>
					eila.heinonen@luottamus.joensuu.fi
				</a>
			</Grid>
		</Grid>
	);
};

export default KukaOlen
